<template>
  <section>
    <land-img
      :height="minorHeight"
      :src="minor.cover"
      flat
      max-width="100%"
      tile
    >
      <land-section
        id="land-user-certificate"
        :space="space || 2"
      >
        <v-container
          style="max-width: 1000px; padding: 0 16px;"
        >
          <v-row
            no-gutters
            justify="center"
          >
            <v-col
              cols="12"
              md="10"
            >
              <certificate-identity
                v-if="step === 'identity'"
                @confirm="onIdentityConfirm"
              />
              <certificate-steps-new v-else />
            </v-col>
          </v-row>
        </v-container>
      </land-section>
    </land-img>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  const DEF_HT = 800

  export default {
    components: {
      CertificateIdentity: () => import('@/pages/sections/my/CertificateIdentity.vue'),
      CertificateStepsNew: () => import('@/pages/sections/my/CertificateStepsNew.vue'),
    },
    data () {
      return {
        minor: {},
        points: {
          xs: 620,
          sm: 720,
          md: DEF_HT,
        },
        step: 'identity'
      }
    },
    computed: {
      gradient () {
        const overlay1 = 'rgba(255, 255, 255, 0.45)'
        const overlay2 = 'rgba(255, 255, 255, 0.65)'
        return `to top right, ${overlay1}, ${overlay2}`
      },
      space () {
        const ht = this.getHeight()
        const sp = Math.floor(ht * 0.15)
        return sp
      },
      minorHeight () {
        const ht = this.getHeight()
        return ht
      },
    },
    created () {
      this.getBanner()
    },
    methods: {
      getHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || DEF_HT
        return ht
      },
      getBanner () {
        const me = this

        let params = null
        const executed = function (res) {
          if (res.status) {
            const items = params.items || []
            me.minor = Object.assign({}, items[0] || {})
          }
        }

        params = web.banner.getParams({
          type: web.comm.BannerTypes.LOGIN_MINOR,
          state: web.comm.States.PUBLISH,
          executed
        })
        api.httpx.getItems(params)
      },
      onIdentityConfirm (ev) {
        this.step = 'steps'
      }
    }
  }
</script>
